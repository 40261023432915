<template>
    <el-dialog width="75%">
        <div class="offer-info-container">
            <div id="offerBox" v-if="!type">
                <div class="title-box">
                    <img class="imgs imgs-w-200" src="../../../../assets/baojialogo.jpg" />
                    <div>
                        <p class="o-name-title text-bold">{{ quoteInfos.sellerEnterpriseName }}</p>
                        <!-- <p class="o-name-title-e text-bold">{{quoteInfos.sellerEnterpriseName}}</p> -->
                        <p class="o-title text-bold">报价单</p>
                    </div>
                    <img class="imgs" src="../../../../assets/baojiaweixinma.jpg" />
                </div>
                <div class="company-info text-bold">
                    <div class="company-info-box">
                        <div class="c-info">
                            <p class="c-label">TO：</p>{{ quoteInfos.buyerEnterpriseName || ''}}
                        </div>
                        <div class="c-info">
                            <p class="c-label">地址：</p>{{ quoteInfos.buyerEnterpriseAddress || ''}}
                        </div>
                        <div class="c-info">
                            <p class="c-label">电话：</p>{{ quoteInfos.buyerPhone || ''}}
                        </div>
                        <!-- <div><p class="c-label">传真：</p>{{quoteInfos.to}}</div> -->
                        <div class="c-info">
                            <p class="c-label">联系人：</p>{{ quoteInfos.buyerName || ''}}
                        </div>
                    </div>
                    <div class="company-info-box">
                        <div class="c-info">
                            <p class="c-label">FROM：</p>{{ quoteInfos.sellerEnterpriseName || ''}}
                        </div>
                        <div class="c-info">
                            <p class="c-label">地址：</p>{{ quoteInfos.sellerEnterpriseAddress || ''}}
                        </div>
                        <div class="c-info">
                            <p class="c-label">电话：</p>{{ quoteInfos.sellerPhone || ''}}
                        </div>
                        <!-- <div><p class="c-label">传真：</p>{{quoteInfos.from}}</div> -->
                        <div class="c-info">
                            <p class="c-label">联系人：</p>{{ (quoteInfos.sellerName || '' )+ ' ' + (quoteInfos.sellerPhone || '') }}
                        </div>
                    </div>
                </div>
                <p class="msg-info">感谢惠顾，现将贵公司所需器件报价如下：</p>
                <el-table :data="data" border class="info-table" id="tableBox">
                    <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
                    <el-table-column align="center" label="品牌" prop="brandName"></el-table-column>
                    <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
                    <el-table-column align="center" label="产品型号" prop="barCode"></el-table-column>
                    <el-table-column align="center" label="数量(台)" prop="qty" width="80"></el-table-column>
                    <el-table-column align="center" label="单价(元)" prop="salesPrice" width="150"></el-table-column>
                    <el-table-column align="center" label="金额(元)" prop="amount" width="150"></el-table-column>
                    <el-table-column align="center" label="货期" prop="channel" width="70"></el-table-column>
                </el-table>
                <div class="table-supplement">
                    <p class="col">合计：<span class="c-value">{{ totalAmount.toFixed(2) }}</span></p>
                    <p class="col col-f">人民币大写：<span class="c-value">RMB {{ amountInWords }}</span></p>
                    <p class="col col-f">注：以上报价为含税报价（13%增值税），报价有效期5天。</p>
                </div>
                <div class="f-info">
                    <div>
                        <p>请注意产品型号是否有误，或是否完整！</p>
                        <p>1.{{ quoteInfos.payStatus ?  quoteInfos.payStatus : "预付30%订货，余款到账发货送货。（具体请与销售沟通）" }} </p>
                        <p>2.交货地为需方所在地，运费由供方承担。</p>
                        <p style="color:#005D4B;font-weight: bold;">本报价由盘雷工业供应链提供，详情可上盘雷平台查询。</p>
                    </div>
                    <div class="f-names-info">
                        <p class=" text-bold">{{ quoteInfos.sellerName }} <span class="phone">{{ quoteInfos.sellerPhone
                        }}</span></p>
                        <p>{{ quoteInfos.sellerEnterpriseName }}</p>
                        <p>{{ date }}</p>
                    </div>
                </div>
            </div>
            <div id="offerBox" v-else>
                <div class="title-box">
                    <img class="imgs imgs-w-200" src="../../../../assets/baojialogo.jpg" />
                    <div>
                        <p class="o-name-title text-bold">{{ quoteInfos.sellerEnterpriseName }}</p>
                        <!-- <p class="o-name-title-e text-bold">{{quoteInfos.sellerEnterpriseName}}</p> -->
                        <p class="o-title text-bold">报价单</p>
                    </div>
                    <img class="imgs" src="../../../../assets/baojiaweixinma.jpg" />
                </div>
                <el-table :data="data" border class="info-table" id="tableBox">
                    <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
                    <el-table-column align="center" label="品牌" prop="brandName"></el-table-column>
                    <el-table-column align="center" label="产品名称" prop="productName"></el-table-column>
                    <el-table-column align="center" label="产品型号" prop="barCode"></el-table-column>
                    <el-table-column align="center" label="数量(台)" prop="qty" width="80"></el-table-column>
                    <el-table-column align="center" label="单价(元)" prop="salesPrice" width="150"></el-table-column>
                    <el-table-column align="center" label="金额(元)" prop="amount" width="150"></el-table-column>
                    <el-table-column align="center" label="货期" prop="channel" width="70"></el-table-column>
                    <el-table-column align="center" label="报价人" prop="sellerName" width="70"></el-table-column>

                </el-table>
            </div>


            <div class="btn-box">
                <el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
                <el-button type="success" size="mini" @click="exportOffer">导出报价单</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from "moment"
import { convertCurrency, floatMul, floatAdd } from "../../mixins/handleAmount"

export default {
    name: 'offerInfo',
    props: ['data', 'quoteInfos', 'type'],
    data() {
        return {
            date: moment().format("YYYY/MM/DD"),
            totalAmount: '',
            amountInWords: ''
        }
    },
    created() {
        this.offerTotalAmount()
    },
    methods: {
        offerTotalAmount(){
            let totalAmount = 0
        for (const item of this.data) {
            console.log(this.data);
            item.amount = floatMul(item.qty, item.salesPrice)
            totalAmount += item.amount
        }
        this.totalAmount = totalAmount
        this.amountInWords = convertCurrency(totalAmount)
        },
        exportOffer() { // 导出报价单
            const title = '报价单';
            window.pageYoffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            const imageWrapper = document.querySelector('#offerBox')
            html2Canvas(imageWrapper, {
                // allowTaint: true,
                userCORS: true,
                dpi: 300, // 图片清晰度问题
                background: '#FFFFFF',
                scrollY: 0,
                scrollX: 0,
            }).then(function (canvas) {
                const contentWidth = canvas.width
                const contentHeight = canvas.height
                const pageHeight = contentWidth / 592.28 * 841.89
                const leftHeight = contentHeight
                let position = 0
                const imgWidth = 595.28
                const imgHeight = 592.28 / contentWidth * contentHeight
                const pageData = canvas.toDataURL('image/jpeg', 1.0)
                const PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(title + '.pdf', PDF)
            })
        },
        close() { //关闭弹框
            this.$emit('update:modelValue', false);
        }
    }

}
</script>

<style lang="less" scoped>
.offer-info-container {
    padding: 20px;
}

#offerBox {
    color: #000;
    padding: 10px;

    // background: #cae7cd;
    .text-bold {
        font-weight: bold;
    }

    .title-box {
        display: flex;
        position: relative;
        margin-bottom: 15px;
        justify-content: space-between;

        .imgs {
            width: 150px;
            height: 150px;
        }

        .imgs-w-200 {
            width: 200px;
            height: 100px;
        }
    }

    .o-name-title {
        font-size: 36px;
        text-align: center;
        font-family: fangsong;
        margin: 5px 0;
    }

    .o-name-title-e {
        font-size: 17px;
        text-align: center;
        font-family: fangsong;
    }

    .o-title {
        font-size: 40px;
        text-align: center;
        font-family: fangsong;
        margin-bottom: 10px;
    }

    .company-info {
        display: flex;
        font-family: serif;
        margin-bottom: 10px;
        justify-content: space-around;

        .company-info-box {
            width: 45%;
            font-size: 24px;
            padding: 10px 30px;
            border-radius: 5px;
            box-sizing: border-box;
            border: 1px solid #b8b8b8;

            .c-info {
                padding: 0 0 3px 0;
            }

            .c-label {
                width: 80px;
                line-height: 20px;
                display: inline-block;
            }
        }
    }

    .el-table {
        margin-top: 5px;
        border-color: #333;
        background: transparent;

        &::before,
        &::after {
            background-color: #333;
        }

        /deep/ thead {
            color: #000;
        }

        /deep/ tr {
            // background: transparent;
            th {
                border-color: #333;
                // background: transparent;
            }
        }

        /deep/ .el-table__cell.is-center {
            border-color: #333;
        }

        /deep/ .cell {
            padding: 12px 10px;
        }

        /deep/ .el-table__cell {
            padding: 0;
        }
    }

    .table-supplement {
        text-align: right;
        margin-bottom: 10px;

        .col {
            padding: 10px 10px;
            text-align: right;
        }

        .col-f {
            padding: 0 10px 10px 10px;
        }

        .c-value {
            margin-left: 10px;
        }
    }

    .f-info {
        display: flex;
        margin: 15px 0;
        line-height: 25px;
        justify-content: space-between;

        .phone {
            margin-left: 20px;
        }

        .f-names-info {
            margin-right: 50px;
        }
    }

    .msg-info {
        padding: 5px 0 5px 80px;
    }
}

.btn-box {
    margin: 15px 0;
    text-align: center;
}
</style>
